<template>
    <div class="mainbox_question">
        <div class="courseName">
            <div class="courseName-box" id="courseNameBox">
                <div :id="'courseName' + index" ref="courseNameItems" :class="{
                    'courseName-item': true,
                    'courseName-check': courseNameId == item.kcId,
                }" v-for="(item, index) in courseNameList" :key="index" @click="chooseCourseName(item, index)">
                    {{ item.kcName }}
                </div>
            </div>
            <div class="courseName-change" @click="showChangeCourseName">
                <img src="@/assets/img/questionNew/59.png" />
                <span>切换课程</span>
            </div>
        </div>
        <!-- 题库 -->
        <div class="container">
            <div class="main">
                <div class="title">
                    <span>您已坚持刷题</span>
                    <span class="title-1 ml8">{{ yhItem.dataNum }}</span>
                    <span class="title-2 mr20">天</span>
                    <span>超过</span>
                    <span class="title-1 ml8">{{ yhItem.rate }}</span>
                    <span class="title-2">%</span>
                    <span>的用户</span>
                </div>
                <div class="list">
                    <div @click="showScreen" class="item item-1">
                        <img src="@/assets/img/questionNew/51.png" class="item-z-icon" />
                        <div class="item-z-title">
                            <div>快速智能练习</div>
                            <div>针对当前题库进行智能筛选</div>
                        </div>
                        <canvas id="score" width="90" height="90" ref="score" v-if="showCanvas"></canvas>
                    </div>
                    <div class="item item-2">
                        <div class="item-t">
                            <div>{{ info.subjectNum ? info.subjectNum : 0 }}</div>
                            <div>总题量</div>
                        </div>
                        <div class="item-t">
                            <div>{{ info.makerSubjectNum ? info.makerSubjectNum : 0 }}</div>
                            <div>刷题量</div>
                        </div>
                        <div class="item-t">
                            <div>
                                <span>{{ info.trueRate ? info.trueRate : 0 }}</span>
                                <span>%</span>
                            </div>
                            <div>正确率</div>
                        </div>
                    </div>
                    <div @click="
                        goNext(`/allsixth/fallibleSix?courseNameId=${courseNameId}`)
                        " class="item item-3">
                        <img src="@/assets/img/questionNew/8.png" class="item-z-icon" />
                        <div class="item-z-title">
                            <div>易错题题库</div>
                            <div>
                                <span class="mr12">总题 {{ ycItem.fallibilityNum }}</span>
                                <span>累计消灭 {{ ycItem.wipeNum }}</span>
                            </div>
                        </div>
                        <img src="@/assets/img/questionNew/5.png" class="item-z-arrow" />
                    </div>
                    <div class="item item-2 item-4">
                        <div @click="
                            goNext(
                                `/allsixth/collectSix?courseNameId=${courseNameId}&makerType=2`
                            )
                            " class="item-f">
                            <img src="@/assets/img/questionNew/1.png" />
                            <span>收藏夹</span>
                        </div>
                        <div @click="
                            goNext(
                                `/allsixth/correctionNotebookSix?courseNameId=${courseNameId}&makerType=1`
                            )
                            " class="item-f">
                            <img src="@/assets/img/questionNew/4.png" />
                            <span>错题本</span>
                        </div>
                        <div @click="
                            goNext(
                                `/allsixth/doQuestionRecordSix?courseNameId=${courseNameId}&makerType=1`
                            )
                            " class="item-f">
                            <img src="@/assets/img/questionNew/3.png" />
                            <span>做题记录</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 课程名弹窗 -->
        <div class="mask-fixed" v-if="changeCourseNameShow">
            <div class="m-courseName-box">
                <img src="@/assets/img/questionNew/31.png" class="m-courseName-close" @click="showChangeCourseName" />
                <div class="m-courseName-title">选择切换科目</div>
                <div class="m-courseName-list">
                    <div :class="{
                        'm-courseName-item': true,
                        'm-courseName-check': courseNameId == item.kcId,
                    }" v-for="(item, index) in courseNameList" :key="index" @click="chooseCourseName(item, index)">
                        {{ item.kcName }}
                    </div>
                </div>
            </div>
        </div>
        <!-- 智能练习筛选弹窗 -->
        <div class="mask-fixed mask-fixed1" style="z-index: 2001" v-if="screenShow">
            <screen :courseNameId="courseNameId" :courseNameInfo="courseNameInfo" @close="showScreen" />
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import { getInfo } from "@/api/cookies";
import screen from "@/components/questionNew/screenSix.vue";

import { AnswerClient } from "@/api/task/answerClient";
const answerClient = new AnswerClient();
export default {
    props: {
        bxId: {
            default: "",
            type: Number | String,
        },
        bxType: {
            default: "",
            type: Number | String,
        },
    },
    components: { screen },
    data() {
        return {
            courseNameList: [], // 试卷列表
            info: {},
            getInfo: null,
            courseNameInfo: "",
            courseNameId: "",
            changeCourseNameShow: false,
            showCanvas: false,
            screenShow: false,
            ycItem: {},
            yhItem: {},
        };
    },
    async created() {
        this.userInfo = getInfo();
        if (!this.userInfo) {
            Vue.prototype.goLoginView(false);
            return;
        }
        await this.selectQuestionKeChengList();
        await this.getFallibilityData();
        await this.selectVipResultDay();
    },
    methods: {
        async selectQuestionKeChengList() {
            if (this.bxType == "coursePackageId") {
                answerClient
                    .selectQuestionKeChengList({ coursePackageId: this.bxId })
                    .then(async (res) => {
                        this.courseNameList = res.data;
                        if (this.courseNameList && this.courseNameList.length > 0) {
                            if (sessionStorage.getItem("questionBank-courseNameId")) {
                                const hasId2 = this.courseNameList.find(
                                    (item) =>
                                        item.kcId ==
                                        sessionStorage.getItem("questionBank-courseNameId")
                                );
                                if (hasId2) {
                                    const index = this.courseNameList.findIndex(
                                        (item) =>
                                            item.kcId ==
                                            sessionStorage.getItem("questionBank-courseNameId")
                                    );
                                    await this.chooseCourseName(
                                        this.courseNameList[index],
                                        index
                                    );
                                } else {
                                    await this.chooseCourseName(this.courseNameList[0], 0);
                                }
                            } else {
                                await this.chooseCourseName(this.courseNameList[0], 0);
                            }
                        } else {
                            this.courseNameId = "";
                        }
                    });
            } else {
                answerClient
                    .selectQuestionKeChengList({ classesId: this.bxId })
                    .then(async (res) => {
                        this.courseNameList = res.data;
                        if (this.courseNameList && this.courseNameList.length > 0) {
                            if (sessionStorage.getItem("questionBank-courseNameId")) {
                                const hasId2 = this.courseNameList.find(
                                    (item) =>
                                        item.kcId ==
                                        sessionStorage.getItem("questionBank-courseNameId")
                                );
                                if (hasId2) {
                                    const index = this.courseNameList.findIndex(
                                        (item) =>
                                            item.kcId ==
                                            sessionStorage.getItem("questionBank-courseNameId")
                                    );
                                    await this.chooseCourseName(
                                        this.courseNameList[index],
                                        index
                                    );
                                } else {
                                    await this.chooseCourseName(this.courseNameList[0], 0);
                                }
                            } else {
                                await this.chooseCourseName(this.courseNameList[0], 0);
                            }
                        } else {
                            this.courseNameId = "";
                        }
                    });
            }
        },
        async getFallibilityData() {
            answerClient
                .getFallibilityData({
                    kcId: this.courseNameId,
                    userId: this.userInfo.id,
                })
                .then(async (res) => {
                    this.ycItem = res.data;
                });
        },
        async selectVipResultDay() {
            answerClient
                .selectVipResultDay({
                    kcId: this.courseNameId,
                    userId: this.userInfo.id,
                })
                .then(async (res) => {
                    this.yhItem = res.data;
                });
        },
        async getQuestionKeCheng() {
            answerClient
                .getQuestionKeCheng({
                    kcId: this.courseNameId,
                    userId: this.userInfo.id,
                })
                .then((res) => {
                    this.info = res.data;
                    // this.info.makerRate = 50
                    this.showCanvasFn();
                });
        },
        showCanvasFn() {
            this.showCanvas = true;
            setTimeout(() => {
                this.setCanvas();
            }, 100);
        },
        setCanvas() {
            const canvas = document.getElementById("score");
            const ctx = canvas.getContext("2d");

            const canvasW = 90;
            const canvasH = 90;

            ctx.clearRect(0, 0, canvas.width, canvas.height);

            // 百分比
            ctx.beginPath();
            ctx.arc(canvasW / 2, canvasH / 2, 34, 0, Math.PI * 2);
            ctx.fillStyle = "#fff";
            ctx.fill();
            ctx.font = "bold 18px sans-serif";
            ctx.fillStyle = "#4A6AF0";
            const text = ctx.measureText(this.info.makerRate + "%");
            ctx.fillText(
                this.info.makerRate + "%",
                canvasW / 2 - text.width / 2,
                canvasH / 2 + 7
            );

            // 外环
            ctx.strokeStyle = "#fff";
            ctx.lineWidth = 5;
            ctx.beginPath();
            ctx.arc(canvasW / 2, canvasH / 2, 42, 0, Math.PI * 2);
            ctx.stroke();

            // 外环填充
            const gradient = ctx.createLinearGradient(0, 0, 90, 0);
            gradient.addColorStop("0", "#A5C4FF");
            gradient.addColorStop("1", "#4A6AF0");
            ctx.strokeStyle = gradient;
            ctx.lineWidth = 5;
            ctx.beginPath();
            ctx.arc(
                canvasW / 2,
                canvasH / 2,
                42,
                -(Math.PI / 2),
                Math.PI * 2 * (this.info.makerRate / 100) - Math.PI / 2
            );
            if (this.info.makerRate > 0) {
                ctx.shadowColor = "#A5C4FF";
                ctx.shadowOffsetX = 0;
                ctx.shadowOffsetY = 0;
                ctx.shadowBlur = 0;
            }
            ctx.stroke();
        },
        // 课程名
        async chooseCourseName(item, index) {
            this.courseNameId = item.kcId;
            sessionStorage.setItem("questionBank-courseNameId", this.courseNameId);
            this.courseNameInfo = item;
            const courseNameBox = document.getElementById("courseNameBox");
            if (index != 0) {
                index--;
                // const courseNameItem = this.$refs.courseNameItems[index];
                const courseNameItem = this.courseNameList[index];
                courseNameBox.scrollLeft = courseNameItem.offsetLeft;
            }
            this.changeCourseNameShow = false;
            await this.getQuestionKeCheng();
            await this.getFallibilityData();
            await this.selectVipResultDay();
        },
        // 切换课程名
        showChangeCourseName() {
            this.changeCourseNameShow = !this.changeCourseNameShow;
        },
        // 筛选选项
        showScreen() {
            this.screenShow = !this.screenShow;
        },
    },
};
</script>
<style lang="less" scoped>
.mainbox_question {
    padding-top: 20px;
    width: 100%;
    border-radius: 8px;
    // background: #FFF2F1;
    // background-image: url("~@/assets/img/questionNew/6.png");
    // background-size: 100% 100%;

    .q_til {
        display: flex;
    }
}

.courseName {
    padding: 0 24px;
    margin-bottom: 15px;
    display: flex;

    .courseName-box {
        flex: 1;
        overflow-y: hidden;
        overflow-x: auto;
        white-space: nowrap;
        position: relative;
        transition: all 0.3s;

        .courseName-item {
            display: inline-block;
            height: 36px;
            line-height: 36px;
            font-size: 15px;
            color: #333;
            border-radius: 6px;
            padding: 0 24px;
            margin-right: 8px;
            cursor: pointer;
        }

        .courseName-check {
            background-color: #4a6af0;
            color: #fff;
        }

        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }

    .courseName-change {
        padding: 0 8px;
        height: 36px;
        display: flex;
        align-items: center;
        font-size: 15px;
        color: #21385a;
        cursor: pointer;

        img {
            width: 15px;
            height: 15px;
            margin-right: 6px;
        }
    }
}

.container {
    flex: 1;
    padding: 0 18px;

    .main {
        width: 100%;
        height: 100%;
        background-image: url("~@/assets/img/questionNew/50.png");
        background-size: 100% 100%;
        padding: 24px;

        .title {
            color: #21385a;
            font-size: 18px;
            margin-bottom: 28px;

            .title-1 {
                font-size: 28px;
                color: #4a6af0;
                font-weight: bold;
            }

            .title-2 {
                font-size: 18px;
                color: #4a6af0;
                font-weight: bold;
            }
        }

        .list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .item {
                width: 390px !important;
                height: 128px !important;
                padding: 20px !important;
                border-radius: 12px;
                margin-bottom: 20px;
            }

            .item-1,
            .item-3 {
                width: 427px;
                margin-right: 16px;
                display: flex;
                align-items: center;
                padding: 0 20px;
                cursor: pointer;

                .item-z-icon {
                    width: 49px;
                    height: 48px;
                    margin-right: 18px;
                }

                .item-z-title {
                    flex: 1;

                    div:first-child {
                        font-family: YouSheBiaoTiHei, YouSheBiaoTiHei;
                        font-weight: 800;
                        font-size: 22px;
                        color: #26458a;
                    }

                    div:last-child {
                        font-family: Microsoft YaHei, Microsoft YaHei;
                        font-weight: 400;
                        font-size: 15px;
                        color: #26458a;
                    }
                }
            }

            .item-1 {
                border: 1px solid #a5c4ff;
                background: linear-gradient(309deg, #ecf4ff 0%, #ecf4ff 100%);

                .item-z-title {
                    color: #26458a;
                }

                canvas {
                    // transform: translateX(20px);
                }
            }

            .item-3 {
                background: linear-gradient(314deg, #fcf7e0 0%, #fffcef 100%);
                border: 1px solid #c7a271;

                .item-z-title {
                    color: #794812 !important;

                    >div {
                        color: #794812 !important;
                    }
                }

                .item-z-arrow {
                    width: 36px;
                    height: 36px;
                }
            }

            .item-2 {
                width: 431px;
                border: 1px solid #a5c4ff;
                background-color: #f5f9ff;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .item-t {
                text-align: center;

                div:first-child {
                    margin-bottom: 6px;
                    font-size: 28px;
                    color: #21385a;
                    font-weight: bold;
                }

                div:last-child {
                    color: #21385a;
                    font-size: 16px;
                }

                &:nth-child(2) {
                    padding: 0 30px;
                    margin: 0 30px;
                    position: relative;

                    &::before,
                    &::after {
                        content: "";
                        width: 1px;
                        height: 24px;
                        background-color: #d9ecff;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                    }

                    &::before {
                        left: 0;
                    }

                    &::after {
                        right: 0;
                    }
                }

                &:nth-child(3) {
                    div:first-child {
                        color: #32c89f;

                        span:last-child {
                            font-size: 16px;
                        }
                    }
                }
            }

            .item-f {
                cursor: pointer;
                font-size: 16px;
                color: #21385a;
                display: flex;
                flex-direction: column;
                align-items: center;

                img {
                    width: 50px;
                    height: 50px;
                    margin-bottom: 8px;
                }

                &:nth-child(2) {
                    margin: 0 76px;
                }
            }
        }
    }
}

.m-courseName-box {
    width: 706px;
    border-radius: 16px;
    overflow: hidden;
    padding-bottom: 8px;
    // background-color: #fff;
    background: linear-gradient(180deg, #d6e6ff 0%, #ffffff 20%);
    position: relative;

    .m-courseName-close {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 20px;
        right: 24px;
        cursor: pointer;
    }

    .m-courseName-title {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        padding-top: 24px;
        margin-bottom: 28px;
    }

    .m-courseName-list {
        display: flex;
        flex-wrap: wrap;
        padding-left: 40px;
        max-height: 400px;
        overflow-y: auto;
        overflow-x: hidden;

        .m-courseName-item {
            font-size: 15px;
            color: #333;
            padding: 8px 24px;
            background-color: #f0f0f0;
            border-radius: 6px;
            margin-right: 20px;
            margin-bottom: 20px;
            cursor: pointer;
        }

        .m-courseName-check {
            background-color: #4a6af0;
            color: #fff;
        }
    }
}

.mask-fixed1 {
    padding-top: 140px;

    .e-screen {
        background: linear-gradient(180deg, #d6e6ff 0%, #ffffff 20%);
    }
}
</style>
